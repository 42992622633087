body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    background-color: #fcfcfc;
}

.container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.navbar {
    height: 60px;
    background-color: #007BFF;
    position: relative;
}

.logo img {
    width: 90px;
    margin-inline-start: 20px;
    margin: 0 auto;
    display: block;
}

.menu-icon {
    display: none;
}

.navs-elements ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
}

.navs-elements ul li:not(:last-child) {
    margin-right: 50px;
}

.navs-elements ul a {
    font-size: 14px;
    font-weight: 400;
    color: #2f234f;
    text-decoration: none;
    transition: color 0.3s ease, font-weight 0.3s ease;
}

.navs-elements ul a.active {
    color: #574c4c;
    font-weight: 500;
    position: relative;
}

.navs-elements ul a.active::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #574c4c;
}

/* Dropdown Styles */
.navs-elements ul .dropdown {
    display: none; /* Hidden by default */
    visibility: hidden; /* Hidden by default */
    opacity: 0; /* Make dropdown invisible */
    transition: opacity 0.3s ease, visibility 0.3s ease; /* Smooth transition for visibility */
    position: absolute;
    top: 60px; /* Adjust as needed */
    right: 30;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    list-style-type: none;
    padding: 0;
    min-width: 150px;
}

.navs-elements ul .dropdown.visible {
    display: block; /* Show the dropdown when the visible class is added */
    visibility: visible; /* Ensure it's visible */
    opacity: 1; /* Make it visible */
}

.navs-elements ul .dropdown li {
    padding: 5px;
    text-align: center;
}

.navs-elements ul .dropdown li a {
    color: #2f234f;
    text-decoration: none;
}

.navs-elements ul .dropdown li a:hover {
    background-color: #00b7ff;
}

/* Optional: Style the dropdown arrow */
.navs-elements ul li span {
    margin-left: 5px;
    font-size: 12px;
    color: #999;
}

/* Media Queries */
@media (max-width: 768px) {
    .navs-elements ul li:not(:last-child) {
        margin-right: 25px;
    }

    /* Make sure to adjust the logo for smaller screens */
    .logo img {
        width: 80px;
    }

    /* Dropdown styling for small screens */
    .navs-elements ul .dropdown {
        top: unset;  /* Remove the top property */
        bottom: 50;   /* Align it at the bottom of the container */
        left: 10;
    }
    .navs-elements ul .dropdown li {
        padding: 5px;
        text-align: center;
    }
    /* Make sure the menu icon is visible */
    .menu-icon {
        display: block;
        cursor: pointer;
    }

    .navs-elements ul li {
        display: block;  /* Make the menu items display vertically on smaller screens */
        width: 100%;     /* Make the items take full width */
    }

    .navs-elements ul a {
        font-size: 18px; /* Increase font size on mobile for readability */
        text-align: center;
        padding: 5px;
        border-bottom: 1px solid #ddd; /* Optional: Add border to separate links */
    }
}

@media (max-width: 1100px) {
    .logo img {
        width: 90px;
        margin-right: 20px;
    }
}

@media (max-width: 990px) {
    .menu-icon {
        display: block;
        cursor: pointer;
    }

    .navs-elements ul .dropdown {
        top: unset;  /* Remove the top property */
        bottom: 50;   /* Align it at the bottom of the container */
        right: 50;
    }
    .navs-elements ul .dropdown li {
        padding: 5px;
        text-align: left;
    }
    .navs-elements {
        position: absolute;
        right: 0;
        top: 60px;
        background-color: #fcfcfc;
        width: 0px;
        height: calc(100vh - 60px);
        transition: all 0.3s ease-in;
        overflow: hidden;
        z-index: 999;
    }

    .navs-elements.active {
        width: 270px;
    }

    .navs-elements ul {
        display: flex;
        flex-direction: column;
        padding: 5px;
        margin-top: 20px;
    }

    .navs-elements ul li {
        margin-right: unset;
        margin-top: 22px;
    }

    .navs-elements ul a {
        font-size: 18px;
        text-align: center;
        padding: 5px;
        border-bottom: none;
    }

    .logo img {
        width: 90px;
        margin-inline-start: 0;
        margin-inline-end: 0;
        display: block;
        margin: 0 auto;
    }
}
