.strategy-card {
    border: 4px;
    background-color: rgb(232, 235, 239);
    border-radius: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    /* same as gap-4 */
    margin: 1rem;
    padding: 1rem;
    /* same as p-4 */
}

.main-sta-card {
    padding: 0px;
    margin: auto;
}

 .sub-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
 }

/* Add this to your strategy.css */
@media (max-width: 524px) {
    .strategy-card h2 {
      font-size: 0.875rem; /* Text size for smaller screens */
    }
  
    .strategy-card p {
      font-size: 0.75rem; /* Smaller text size for paragraphs */
    }
  
    .text-center {
      font-size: 0.75rem; /* Apply small text size to centered text as well */
    }
  
    .btn {
      font-size: 0.75rem; /* Reduce button text size */
    }
    .sub-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
     }
  }
  
 /* Add this to your strategy.css */
@media (max-width: 344px) {
    .strategy-card h2 {
      font-size: 0.575rem; /* Text size for smaller screens */
    }
  
    .strategy-card p {
      font-size: 0.45rem; /* Smaller text size for paragraphs */
    }
  
    .text-center {
      font-size: 0.45rem; /* Apply small text size to centered text as well */
    }
  
    .btn {
      font-size: 0.45rem; /* Reduce button text size */
    }
    .sub-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
     }
  }

  @media (max-width: 296px) {
    .strategy-card h2 {
      font-size: 0.375rem; /* Text size for smaller screens */
    }
  
    .strategy-card p {
      font-size: 0.35rem; /* Smaller text size for paragraphs */
    }
  
    .text-center {
      font-size: 0.35rem; /* Apply small text size to centered text as well */
    }
  
    .btn {
      font-size: 0.35rem; /* Reduce button text size */
      /* padding: 0.25rem 0.5rem;  */
    }
  
    .sub-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
    }
  }
  