/* Dashboard.css */

.dashboard {
  font-family: Arial, sans-serif;
  margin: 40px;
  padding: 30px;

}

h5 {
  text-align: start;
  margin-bottom: 20px;
  padding: 5px;
}

h6 {
  text-align: center;
  margin-bottom: 20px;
  color: #1a8ae6;
  padding: 10px;
}

/* .filters-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  width: min(800px, 100%);
} */

.filter-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.filter-row label {
  flex: 1;
  font-size: 14px;
  font-weight: bold;
  color: #0056b3;
  display: flex;
  flex-direction: column;
}

.filter-row select {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
}

.spot-price {
  flex: 1;
  font-size: 14px;
  font-weight: bold;
  color: #ff5722;
  display: flex;
  flex-direction: column;
}

.spot {
  margin-bottom: 5px;
  padding: 8px;
  border-radius: 4px;
}

.charts {
  margin-top: 20px;
}

.chart-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.chart {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 15px;
  background-color: white;
}

.chart-group {
  flex: 0 0 30%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.chart.small {
  position: relative;
  /* Ensure the chart takes up the full container */
}

.oi-details {
  position: absolute;
  top: 50%;
  left: 50%;
  /* transform: translate(-50%, -50%); */
  background-color: rgba(246, 246, 246, 0.986);  /* Dark background for better visibility */
  color: white;  /* White text color */
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  z-index: 10;
}
.active-bar:hover {
  fill-opacity: 0.8; /* Change opacity on hover */
  cursor: pointer;  /* Make the cursor a pointer to show it's interactive */
}

.active-bar:active {
  fill-opacity: 0;  /* Keep the bar fully opaque when selected */
}
.filters-container,
.chart.large {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: white;
  flex: 0 0 70%;
  width: 100%;
  max-width: 70%; 
  margin-right: auto; 
}

.toggle-button {
  padding: 10px 20px;
  background-color: #387908;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.toggle-button:hover {
  background-color: #316b05;
}


.option-chain-data table {
  width: 100%;
  border-collapse: collapse;
}

.option-chain-data th,
.option-chain-data td {
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
}


@media (max-width: 992px) {
  .filters-container {
    flex-direction: column;
    align-items: center;
  }

  .filter-row {
    flex-direction: row;
    gap: 10px;
  }

  .chart-container {
    flex-direction: column;
  }

  .chart-group,
  .chart.large {
    width: 100%;
    margin: 10px 0;
  }

  .unified-card {
    width: 60%;
    padding: 15px;
    gap: 15px;
    max-width: auto;
    margin-left: 190px;
  }

  .option-chain-toggle {
    margin-top: 30px;
  }

  .toggle-button {
    padding: 10px 20px;
    background-color: #387908;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 14px;
  }

  .toggle-button:hover {
    background-color: #316b05;
  }

  .option-chain-data table {
    width: 100%;
    border-collapse: collapse;
  }

  .option-chain-data th,
  .option-chain-data td {
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
  }
}

@media (max-width: 768px) {

  .chart-container {
    flex-direction: column;
  }

  .chart {
    width: 100%;
  }

  .chart-group {
    width: 100%;
  }

  .unified-card,
  .chart.large {
    max-width: 100%; 
    margin-right: 0; 
  }

  .filters-container {
    max-width: 100%;
  }

  .option-chain-data table {
    font-size: 12px;
  }
}

@media (max-width: 576px) {

  h5,
  h6 {
    font-size: 14px;
  }

  .chart {
    padding: 10px;
  }

  .toggle-button {
    font-size: 12px;
    padding: 8px 15px;
  }

  .filter-row label,
  .filter-row select {
    font-size: 10px;
  }
 
}

@media (max-width: 440px) {
  
  /* Adjust font size for Symbol, Expiry Date, Filter Range, and Spot Price */
  .filter-row label,
  .filter-row select {
    font-size: 8px;  /* Adjust as needed */
  }

  .spot-price {
    font-size: 5px;  
    flex-direction: column;
  }

  /* Ensure the filter-row behaves horizontally */
  .filter-row {
    flex-direction: row !important;  /* Ensure it's overridden */
    gap: 5px; /* Adjust as necessary */
  }

  h5, h6 {
    font-size: 10px;  /* Adjust header size for smaller screens */
  }

  /* Modify the toggle button style for smaller screens */
  .toggle-button {
    font-size: 10px;
    padding: 6px 12px;
  }

  /* Adjust chart container for small screen */
  .chart-container {
    flex-direction: column !important; /* Ensure it's vertically stacked */
  }

  .chart-group,
  .chart.large {
    width: 100%;
    margin: 10px 0;
  }

  /* Adjust table font size in option chain */
  .option-chain-data table {
    font-size: 10px; /* Smaller font for better display */
  }

  /* Make sure the filters container works well for very small screens */
  .filters-container {
    flex-direction: column !important;
    align-items: center;
  }

  /* Ensure charts stay within smaller screen sizes */
  .chart.small {
    width: 100%;
    height: auto;
  }

  /* Unified card tweaks if necessary for narrow screens */
  .unified-card {
    width: 100%;
    margin-right: 0; /* Full width for small screens */
    padding: 12px;
  }
}

@media (max-width: 390px) {
  
  /* Adjust font size for Symbol, Expiry Date, Filter Range, and Spot Price */
  .filter-row label,
  .filter-row select {
    font-size: 7px;  /* Further reduced for very small screens */
  }

  /* Ensure the filter-row behaves horizontally */
  .filter-row {
    flex-direction: row !important;  /* Ensures it stays row for small screens */
    gap: 5px; /* Reduced gap for tight spaces */
  }

  /* Adjust spot price box and text */
  .spot-price {
    font-size: 7px;
    flex-direction: column;  /* Row layout for spot price */
  }

  h5, h6 {
    font-size: 9px;  /* Reduced header size for very small screens */
  }

  /* Modify the toggle button style for smaller screens */
  .toggle-button {
    font-size: 9px;
    padding: 5px 10px;
  }

  /* Adjust chart container for small screen */
  .chart-container {
    flex-direction: column !important; /* Ensures vertical stacking */
  }

  .chart-group,
  .chart.large {
    width: 100%;
    margin: 5px 0; /* Reduced margin */
  }

  /* Adjust table font size in option chain */
  .option-chain-data table {
    font-size: 9px; /* Smaller font for better display */
  }

  /* Make sure the filters container works well for very small screens */
  .filters-container {
    flex-direction: column !important;
    align-items: center;
  }

  /* Ensure charts stay within smaller screen sizes */
  .chart.small {
    width: 100%;
    height: auto;
  }

  /* Unified card tweaks if necessary for narrow screens */
  .unified-card {
    width: 100%;
    margin-right: 0; /* Full width for small screens */
    padding: 10px;  /* Reduced padding */
  }
}

@media (max-width: 368px) {
  
  /* Adjust font size for Symbol, Expiry Date, Filter Range, and Spot Price */
  .filter-row label,
  .filter-row select {
    font-size: 5px;  /* Further reduced for very small screens */
  }

  /* Ensure the filter-row behaves horizontally */
  .filter-row {
    flex-direction: column !important;  /* Ensures it stays row for small screens */
    gap: 3px; /* Reduced gap for tight spaces */
  }

  /* Adjust spot price box and text */
  .spot-price {
    font-size: 5px;
    flex-direction: column;  /* Row layout for spot price */
  }

  h5, h6 {
    font-size: 5px;  /* Reduced header size for very small screens */
  }

  /* Modify the toggle button style for smaller screens */
  .toggle-button {
    font-size: 5px;
    padding: 5px 10px;
  }

  /* Adjust chart container for small screen */
  .chart-container {
    flex-direction: column !important; /* Ensures vertical stacking */
  }

  .chart-group,
  .chart.large {
    width: 100%;
    margin: 5px 0; /* Reduced margin */
  }

  /* Adjust table font size in option chain */
  .option-chain-data table {
    font-size: 5px; /* Smaller font for better display */
  }

  /* Make sure the filters container works well for very small screens */
  .filters-container {
    flex-direction: column !important;
    align-items: center;
  }

  /* Ensure charts stay within smaller screen sizes */
  .chart.small {
    width: 100%;
    height: auto;
  }

  /* Unified card tweaks if necessary for narrow screens */
  .unified-card {
    width: 100%;
    margin-right: 0; /* Full width for small screens */
    padding: 10px;  /* Reduced padding */
  }
}

@media (max-width: 1100px) {
  .unified-card {
    width: 100%;
    margin-right: 190px;
    padding: 15px;
  }
}