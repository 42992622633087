/* Default stock container styles */
.stock {
  font-family: Arial, sans-serif;
  margin: 50px;
  padding: 5px;
  margin-top: 20px;
}

/* Market data section */
.marketData {
  text-align: start;
  padding: 20px;
  margin: 10px 0; 
}

/* Headings with specific styles */
h4 {
  text-align: center;
  margin-bottom: 20px;
  color: #090909;
  padding: 10px;
  font-weight: 30px;
}

/* Card styles with background color, border, and shadow */
.cards {
  background-color: #f9f9f9f5;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Flexbox layout for rows */
.rows {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

/* Cards for stocks in a larger size */
.stocks-cards {
  width: 60%;
  padding: 10px;
  margin-top: -10px;
}

/* Small column size for other content (e.g., sidebar) */
.col-4 {
  width: 40%;
}

/* Media queries for responsive design */

/* Tablet and below (up to 992px width) */
@media (max-width: 992px) {
  .stocks-cards {
    width: 70%;
    padding: 10px;
  }

  .col-4 {
    width: 30%;
  }
}

@media (min-width: 1300px) {
  .table-list-stock {
   margin: auto;
   max-width:"1100px"; /* Adjust the width as needed */
  }
}

/* Mobile and below (up to 768px width) */
@media (max-width: 768px) {
  .stocks-cards,
  .col-4 {
    width: 100%;
    padding: 20px;
  }

  .rows {
    gap: 10px; /* Reduces gap between cards on smaller screens */
  }

  .cards {
    padding: 15px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  }

  /* Adjust stock container's margins */
  .stock {
    margin: 20px;
  }
}

/* Mobile phones (up to 480px width) */
@media (max-width: 480px) {
  /* Stock container adjustments */
  .stock {
    margin: 10px auto; /* Center and reduce margin */
    padding: 5px;
  }

  /* Adjust the width and padding for cards */
  .stocks-cards,
  .col-4 {
    width: 80%; /* Make cards wider but not full width */
    padding: 15px; /* Reduced padding for better fit */
  }

  /* Make the marketData font smaller and more readable */
  .marketData {
    font-size: 14px; /* Reduces font size for small screens */
  }

  /* Card styles - reduced padding and border-radius for mobile */
  .cards {
    padding: 12px; /* Reduced padding for a compact look */
    border-radius: 3px; /* Slightly smaller border-radius */
  }

  /* Ensure cards stack properly and are centered */
  .rows {
    flex-direction: column; /* Stack cards vertically */
    gap: 10px; /* Reduced gap between stacked cards */
  }

  /* Adjust heading font size and alignment for small screens */
  h4 {
    font-size: 18px; /* Smaller font for headings */
    text-align: center; /* Ensure centered alignment */
    padding: 5px; /* Padding to ensure spacing around the heading */
  }

  /* Optional: Adjust any other text sizes or layout issues */
  .cards p {
    font-size: 13px; /* Smaller font for any paragraph text inside cards */
  }
}

/* Mobile phones (up to 440px width) - Adjustments for stocks-cards only */
@media (max-width: 440px) {
  .stocks-cards {
    width: 50%; /* Ensure the cards take up 90% of the screen width */
    margin: auto; /* Center the cards */
    padding: 5px; /* Reduced padding for a compact look */
  }

  /* Adjust card text and content layout inside stocks-cards */
  .stocks-cards .cards {
    padding: 10px; /* Smaller padding for cards */
    border-radius: 3px; /* Slightly reduced border-radius for mobile look */
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1); /* Keep light shadow for compact design */
  }

  /* Ensure that the list or text inside the card is readable */
  .stocks-cards .cards p {
    font-size: 10px; /* Reduce font size for better readability */
  }

  /* Adjust any nested lists or small items inside cards */
  .stocks-cards .cards ul {
    font-size: 10px; /* Reduce list text size */
  }
}

