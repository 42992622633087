/* Global styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures the footer stays at the bottom */
}

/* Header Section */
header {
  background-color: #333;
  color: #fff;
  padding: 10px 0;
  text-align: center;
  width: 100%;
}

/* Strategy Table */
#strategy {
  background-color: white;
  padding: 20px;
  margin: 60px;
  margin-left: 180px;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 90%;
  max-width: 900px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Heading inside Strategy Table */
h3 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  text-align: center;
}

.text-center {
  text-align: center;
  margin-top: 3rem;
}

/* Table Styles */
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 12px 15px;
  text-align: left;
}

th {
  background-color: #f0f0f0;
  color: #333;
}

td {
  background-color: #fff;
  color: #333;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Button Styles */
button {
  padding: 8px 16px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 14px;
}

button:hover {
  opacity: 0.8;
}

button.bg-green {
  background-color: #28a745;
  color: white;
}

button.bg-red {
  background-color: #dc3545;
  color: white;
}

/* Footer */
footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px 0;
  width: 100%;
  position: relative;
  bottom: 0;
  flex-shrink: 0;
}

/* Flex container to ensure footer stays at the bottom */
.d-flex {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

/* Responsive Design */
@media screen and (max-width: 1024px) {
  /* Adjust Strategy Table */
  #strategy {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
  }

  /* Adjust Table */
  table {
    font-size: 12px;
  }

  th, td {
    padding: 10px;
  }

  /* Adjust Buttons */
  button {
    padding: 10px 14px;
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  /* Center the Strategy Table */
  #strategy {
    width: 90%;
    padding: 15px;
    margin-left: auto;
    margin-right: auto; /* Centering the table */
  }

  /* Adjust Table */
  table {
    font-size: 10px;
  }

  th, td {
    padding: 10px 12px;
  }

  /* Adjust Buttons */
  button {
    padding: 8px 12px;
    font-size: 12px;
  }

  /* Adjust Header */
  header {
    padding: 8px 0;
  }

  /* Make Footer content stack vertically */
  footer {
    font-size: 12px;
  }
}

@media screen and (max-width: 480px) {
  /* Center the Strategy Table */
  #strategy {
    width: 90%;
    padding: 15px;
    margin-left: auto;
    margin-right: auto;
  }

  /* Adjust Table */
  table {
    font-size: 10px;
  }

  th, td {
    padding: 8px 10px;
  }

  /* Adjust Buttons */
  button {
    font-size: 10px;
    padding: 5px 10px;
  }

  /* Adjust Header */
  header {
    padding: 6px 0;
  }

  /* Adjust Footer */
  footer {
    font-size: 10px;
  }
}
