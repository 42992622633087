#mains {
    position: relative;
    margin: 0 auto;
    padding: 20px;
    width: 90%;
    top: 40%;
}

.names {
    font-family: 'Montserrat', sans-serif;
    max-width: 550px;
    margin: 0 auto;
    text-align: center;
}

.names h1 {
    font-size: 2.5rem;
    line-height: 1.4;
    margin-bottom: 20px;
}

.names h1 span {
    color: #007BFF;
}

.names .detail {
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.button-group {
    display: flex;
    flex-wrap: wrap; /* Allow buttons to wrap into the next line if needed */
    gap: 10px; /* Space between buttons */
    justify-content: center; /* Center the buttons horizontally */
}

.button-group button {
    padding: 5px 10px;
    font-size: 0.5rem;
    border: none;
    background-color: #007BFF;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 15vw; /* Fixed width for all screen sizes */
}

.button-group button:hover {
    background-color: #0056b3;
}

.rows {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.image {
    max-width: 100%;
    height: auto;
}

.top{
    margin-top: 30px;
}
@media screen and (max-width: 1200px) {
    .mains {
        position: relative;
        margin: 0 auto;
        padding: 30px;
        width: 90%;
        top: 40%;
    }

    .rows {
        flex-direction: column;
        padding: 40px;
    }

    .names {
        text-align: left;
    }

    .image {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
    }

    /* Button group layout same as all screen sizes */
    .button-group {
        flex-direction: row;  /* Buttons in a row */
        justify-content: center;
        flex-wrap: wrap; /* Allow wrapping of buttons */
    }
    .button-group button {
        width: 23vw; /* Adjust width for smaller screens */
    }
}

@media screen and (max-width: 920px) {
    .names {
        text-align: center;
    }

    .names h1 {
        font-size: 2rem;
        line-height: 1.4;
        margin-bottom: 20px;
    }

    .rows {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    /* Button group layout same as all screen sizes */
    .button-group {
        flex-direction: row;  /* Buttons in a row */
        justify-content: center;
        flex-wrap: wrap; /* Allow wrapping of buttons */
    }
}

@media screen and (max-width: 882px) {
    .button-group {
        flex-direction: row;  /* Keep the buttons in row direction */
        justify-content: center;  /* Distribute buttons evenly */
        flex-wrap: wrap;  /* Allow the buttons to wrap into the next line */
    }
    .button-group button {
        width: 23vw; /* Adjust width for smaller screens */
    }
}

@media screen and (max-width: 655px) {
    .button-group {
        flex-direction: row;  /* Keep the buttons in row direction */
        justify-content: center;  /* Distribute buttons evenly */
        flex-wrap: wrap;  /* Allow the buttons to wrap into the next line */
    }

    .button-group button {
        width: 23vw; /* Adjust width for smaller screens */
    }
}

@media screen and (max-width: 576px) {
    .button-group {
        flex-direction: row;  /* Keep the buttons in row direction */
        justify-content: center;  /* Distribute buttons evenly */
        flex-wrap: wrap;  /* Allow the buttons to wrap into the next line */
    }

    .button-group button {
        width: 23vw; /* Adjust width for smaller screens */
        justify-content: center;
    }
    .names h6 {
        font-size: x-small;
    }
}

/* Existing styles remain the same */

@media screen and (max-width: 397px) {
    /* For the rows, make the layout more flexible for small screens */
    .rows {
        flex-direction: row;  /* Stack the elements vertically */
        justify-content: center; /* Center align the content */
        flex-wrap: wrap;
    }

    /* Adjust text size for h1 and paragraph */
    .names h1 {
        font-size: 1.5rem; /* Smaller font size for small screens */
        line-height: 1.3;
        margin-bottom: 20px;

    }
    .names .detail {
        font-size: 1rem; /* Smaller font size for the paragraph */
    }

    /* Adjust buttons to fit better */
    .button-group button {
        width: 20vw;  /* Make buttons take up more space and adjust for small screens */
        justify-content: center;
    }
    .button-group button h6 {
        font-size: 0.5rem; /* Smaller font size for text inside buttons */
    }
    /* Center the image to fit small screens */
    .image {
        width: 100%; /* Make the image responsive */
        height: auto;
        margin-top: 10px;
    }
}

@media screen and (max-width: 348px) {
    /* For the rows, make the layout more flexible for small screens */
    .rows {
        flex-direction: row;  /* Stack the elements vertically */
        justify-content: center; /* Center align the content */
        flex-wrap: wrap;
    }

    /* Adjust text size for h1 and paragraph */
    .names h1 {
        font-size: 1.5rem; /* Smaller font size for small screens */
        line-height: 1.3;
        
    }

    .names .detail {
        font-size: 1rem; /* Smaller font size for the paragraph */
    }

    /* Adjust buttons to fit better */
    .button-group button {
        width: 15vw;  /* Make buttons take up more space and adjust for small screens */
        justify-content: center;
    }
    .button-group button h6 {
        font-size: 0.3rem; /* Smaller font size for text inside buttons */
    }
    /* Center the image to fit small screens */
    .image {
        width: 100%; /* Make the image responsive */
        height: auto;
        margin-top: 10px;
    }
}

@media screen and (max-width: 300px) {
    /* Adjust the rows layout to stack items vertically */
    .rows {
        flex-direction: row;  /* Stack elements vertically */
        justify-content: center; /* Center align the content */
        flex-wrap: wrap;
    }

    /* Make h1 text smaller for better fitting */
    .names h1 {
        font-size: 1.2rem; /* Smaller font size */
        line-height: 1.3;
    }

    .names h6 {
        font-size: x-small;
    }
    /* Reduce the size of the detail text */
    .names .detail {
        font-size: 0.9rem; /* Slightly smaller font size for detail text */
    }

    /* Adjust button width to fit smaller screens */
    .button-group button {
        width: 17vw;  /* Ensure buttons have appropriate width */
        font-size: 0.4rem; /* Make button text smaller */
        padding: 3px 8px;  /* Adjust padding for better fit */
        justify-content: center;
    }

    /* Ensure image fits smaller screen and remains centered */
    .image {
        width: 100%; /* Ensure the image fits screen */
        height: auto;
        margin-top: 5px; /* Reduce margin */
    }
}
